















































































import { Component, Prop, Watch } from 'vue-property-decorator';
import StepBaseComponent from '@/components/shipArrival/create/steps/StepBaseComponent.vue';
import { namespace } from 'vuex-class';
import Vessel from '@/models/Vessel.model';
import Voyage from '@/models/Voyage.model';
import { vesselStoreActions, vesselStoreGetter } from '@/store/vessel.store';
import { shipOwnerStoreActions, shipOwnerStoreGetter } from '@/store/shipOwner.store';
import ShipOwner from '@/models/ShipOwner.model';

const VesselStore = namespace('vessel');
const ShipOwnerStore = namespace('shipOwner');

@Component({})
export default class Step1Component extends StepBaseComponent {

    @Prop({ default: false })
    public isLoading!: boolean;

    @VesselStore.Action(vesselStoreActions.GET_ALL)
    public getAllVessels!: (payload?: { filterText: string }) => Promise<Vessel[]>;

    @VesselStore.Getter(vesselStoreGetter.LIST_ALL)
    public _allVessels!: Vessel[];

    get allVessels(): Vessel[] {
      return this._allVessels.filter(vessel => vessel.status);
    }

    @ShipOwnerStore.Action(shipOwnerStoreActions.GET_ALL)
    public getAllShipOwners!: (payload?: { filterText: string }) => Promise<ShipOwner[]>;

    @ShipOwnerStore.Getter(shipOwnerStoreGetter.LIST_ALL)
    public _allShipOwners!: ShipOwner[];

    get allShipOwners(): ShipOwner[] {
      return this._allShipOwners.filter(owner => owner.status);
    }

    public selectedShipOwner: ShipOwner | null = null;

    public vesselLoading = false;

    private isSubmitted: boolean = false;
    private wasShipOwnerTouched: boolean = false;
    private wasVesselTouched: boolean = false;

    public get isFormInvalid(): boolean {
        return this.isSubmitted && (!(this.currentShipArrival.voyage as Voyage).vessel || !this.selectedShipOwner);
    }

    public get shipOwnerError(): string | null {
        return (this.wasShipOwnerTouched || this.isSubmitted) && !this.selectedShipOwner ? this.$t('ERROR_MESSAGES.REQUIRED').toString() : null;
    }

    public get vesselError(): string | null {
        return (this.wasVesselTouched || this.isSubmitted) && !(this.currentShipArrival.voyage as Voyage).vessel ? this.$t('ERROR_MESSAGES.REQUIRED').toString() : null;
    }

    @Watch('isFormInvalid', { immediate: true })
    private onInvalidChange() {
        this.$emit('is-invalid-change', this.isFormInvalid)
    }

    public async created() {
        if (this.currentShipArrival.shipOwner) {
            this.selectedShipOwner = this.currentShipArrival.shipOwner as ShipOwner;
        }

        if ((this.currentShipArrival.voyage as Voyage).vessel) {
            this.vesselLoading = true;
            await this.getAllVessels();
            this.vesselLoading = false;
        }
        await this.getAllShipOwners();
    }

    public mounted() {
        // Pass component to parent for validation
        this.$emit('mounted', this);
    }

    private async updateVessels(filterValue?: string) {
        try {
            this.vesselLoading = true;
            await this.getAllVessels(filterValue ? { filterText: filterValue } : undefined);
        } catch (e) {
            // TODO: Show error
        } finally {
            this.vesselLoading = false;
        }
    }

    public selectedShipOwnerChanged() {
        this.wasShipOwnerTouched = true;
        this.$forceUpdate();
    }

    public selectedVesselChanged() {
        this.wasVesselTouched = true;
        this.$forceUpdate();
    }

    public validate(): boolean {
        this.isSubmitted = true;
        return !this.isFormInvalid;
    }

    public async nextStepClicked() {
        this.validate();

        if (!this.isFormInvalid) {
            this.currentShipArrival.shipOwner = this.selectedShipOwner as ShipOwner;
            this.setCurrentArrival(this.currentShipArrival);
            this.$emit('next-step');
        }
    }

    public onFinish() {
        this.validate();

        if (!this.isFormInvalid) {
            this.$emit('finish');
        }
    }
}
