

import { mixins } from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixin';
import { namespace } from 'vuex-class';
import ShipArrival from '@/models/ShipArrival.model';
import { shipArrivalStoreGetter, shipArrivalStoreMutations } from '@/store/shipArrival.store';
import Voyage from '@/models/Voyage.model';

const ShipArrivalStore = namespace('shipArrival');

export default class StepBaseComponent extends mixins(ErrorMessageHandlerMixin) {

    @ShipArrivalStore.Getter(shipArrivalStoreGetter.CURRENT_ARRIVAL)
    public _currentShipArrival!: ShipArrival;

    public get currentShipArrival(): ShipArrival {
        return this._currentShipArrival;
    }

    @ShipArrivalStore.Mutation(shipArrivalStoreMutations.SET_CURRENT_ARRIVAL)
    public setCurrentArrival!: (shipArrival: ShipArrival) => void;

    @ShipArrivalStore.Mutation(shipArrivalStoreMutations.SET_VOYAGE_FOR_CURRENT_SHIP_ARRIVAL)
    public setVoyageForCurrentShipArrival!: (value: Voyage) => void;

    get isEditMode(): boolean {
        return !!this.currentShipArrival.id;
    }

    public validate(): boolean {
        throw new Error('overwrite me!');
    }
}

